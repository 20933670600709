import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import StatusTanahTable from "./StatusTanahTable";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";

const StatusTanah = () => {
  const [data, setData] = useState([])
  useEffect(()=>{
    UseAPI('status_tanah',{},'GET').then((resp)=>{
      console.log(resp);
      setData(resp);
    });  
  },[])

  const handleDelete = (id) => {
    console.log("hapus",id);
    UseAPI(`status_tanah/${id}`,'','DELETE').then((resp) => {
      if (resp.status === 'ok') {
        setData(resp.data);
        swal(resp.pesan, {
          icon: "success",
        });    
        // window.location.reload();
      } else {
        swal(resp.pesan, {
          icon: "danger",
        });    
      }

    })

  }

  return (
    <Fragment>
      <PageTitle
        activeMenu="Status Tanah"
        motherMenu="Dashboard"
        pageContent="Status Tanah"
      />
      <div className="row">
		<StatusTanahTable 
      title="Daftar"
      data={data}
      onDelete={handleDelete}
    />
      </div>
    </Fragment>
  );
};

export default StatusTanah;
