import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";


const BangunanTambahForm = () => {
  const { tanah_id } = useParams();
  const [data, setData] = useState([])
  const [tanah, setTanah] = useState([])
  const [field, setField] = useState([])
  const [optionData,setOptionData] = useState([]);
  let tanggal_sekarang = new Date();
  tanggal_sekarang = tanggal_sekarang.getFullYear()+'-'+(tanggal_sekarang.getMonth()+1)+'-'+tanggal_sekarang.getDate();
  console.log(typeof tanggal_sekarang);
  const [inputs, setInputs] = useState({
    kode_golongan:'00'
    ,kode_bidang:'00'
    ,kode_kelompok:'00'
    ,kode_sub_kelompok:'00'
    ,kode_sub_sub_kelompok:'000'
    ,golongan_id:0
    ,bidang_id:0
    ,kelompok_id:0
    ,sub_kelompok_id:0
    ,sub_sub_kelompok_id:0
    ,tanah_id:parseInt(tanah_id)
    ,harga_perolehan: 0
    ,keterangan_bangunan: ""
    ,luas_bangunan: 0
    ,nama_bangunan: ""
    ,pengelola: ""
    ,penggunaan: ""
    ,waktu_perolehan: tanggal_sekarang
    
  });

  const [golongan, setGolongan] = useState({});
  const [bidang, setBidang] = useState({});
  const [kelompok, setKelompok] = useState({});
  const [sub_kelompok, setSubKelompok] = useState({});
  const [sub_sub_kelompok, setSubSubKelompok] = useState({});
  const handleChange = (event) => {
    console.log(event);
    if (!event.target) {
      setInputs(values => ({...values, [event.name]: event.value}))
    } else {      
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
        case 'dokumen_sertifikat':
        case 'dokumen_tanah':
          setInputs(values => ({...values, [name]: event.target.files[0]}))
          break;
        default:
          setInputs(values => ({...values, [name]: value}))
          break;
      }
    }
  }
  
  const handleChangeKodeBarang = (event) => {
    console.log("event",event);    
    let level_kode = event.level_kode;
    let  kode_barang = event.kode_barang;
    let name = event.name;
    let kode_golongan = event.kode_golongan;
    let kode_bidang = event.kode_bidang;
    let kode_kelompok = event.kode_kelompok;
    let kode_sub_kelompok = event.kode_sub_kelompok;
    let kode_sub_sub_kelompok = event.kode_sub_sub_kelompok;

    let id = event.id;
    // let kode_dagri = event.kode_dagri;
    // console.log(event);
    // handleChange(event);
    switch (level_kode) {
      case 1:
        golongan.map((item,key)=>{
          if (item.id===id) {
            setInputs(values => ({...values, ['golongan_id']: key}))
          }
        })
        setInputs(values => ({...values, ['kode_golongan']: kode_golongan}))
        setInputs(values => ({...values, ['kode_bidang']: '00'}))
        setInputs(values => ({...values, ['kode_kelompok']: '00'}))
        setInputs(values => ({...values, ['kode_sub_kelompok']: '00'}))
        setInputs(values => ({...values, ['kode_sub_sub_kelompok']: '000'}))

        setInputs(values => ({...values, ['bidang_id']: 0}))
        setInputs(values => ({...values, ['kelompok_id']: 0}))
        setInputs(values => ({...values, ['sub_kelompok_id']: 0}))
        setInputs(values => ({...values, ['sub_sub_kelompok_id']: 0}))
        setInputs(values => ({...values, [name]: kode_golongan}))   
        setBidang([{
          label:'Pilih',value:'00',name:'kode_bidang',kode_bidang:'00',level_kode:2          
        }])     
        setKelompok([{
          label:'Pilih',value:'00',name:'kode_kelompok',kode_kelompok:'00',level_kode:3          
        }])     
        setSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_kelompok',kode_sub_kelompok:'00',level_kode:4          
        }])     
        setSubSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_sub_kelompok',kode_sub_sub_kelompok:'000',level_kode:5          
        }])     
        UseAPI('master_kode_barang/level/2/'+kode_golongan,{},'GET').then((resp)=>{
          console.log('master_kode_barang/level/2/'+kode_golongan,resp);
          let result = [];
          result.push({label:'Pilih',value:'00',name:'kode_bidang'
          ,kode_golongan:kode_golongan
          ,kode_bidang:'00'
          ,kode_kelompok:'00'
          ,kode_sub_kelompok:'00'
          ,kode_sub_sub_kelompok:'000'      
          ,level_kode:2
        })

          resp.map((item)=>{
            result.push({
              label:item.nama_master_kode_barang
              ,value:item.kode_barang
              ,name:'kode_bidang'
              ,kode_golongan:item.kode_golongan
              ,kode_bidang:item.kode_bidang
              ,kode_kelompok:item.kode_kelompok
              ,kode_sub_kelompok:item.kode_sub_kelompok
              ,kode_sub_sub_kelompok:item.kode_sub_sub_kelompok
              ,level_kode:2
              ,id:item.id
            })
          })
          setBidang(result);
        });    
        break;
      case 2:
        bidang.map((item,key)=>{
          if (item.id===id) {
            setInputs(values => ({...values, ['bidang_id']: key}))
          }
        })

        setInputs(values => ({...values, ['kode_kelompok']: '00'}))
        setInputs(values => ({...values, ['kode_sub_kelompok']: '00'}))
        setInputs(values => ({...values, ['kode_sub_sub_kelompok']: '000'}))

        setInputs(values => ({...values, ['kelompok_id']: 0}))
        setInputs(values => ({...values, ['sub_kelompok_id']: 0}))
        setInputs(values => ({...values, ['sub_sub_kelompok_id']: 0}))
        setInputs(values => ({...values, [name]: kode_bidang}))

        setKelompok([{
          label:'Pilih',value:'00',name:'kode_kelompok',kode_kelompok:'00',level_kode:3          
        }])     
        setSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_kelompok',kode_sub_kelompok:'00',level_kode:4          
        }])     
        setSubSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_sub_kelompok',kode_sub_sub_kelompok:'000',level_kode:5          
        }])     
     

        UseAPI('master_kode_barang/level/3/'+kode_golongan+'.'+kode_bidang,{},'GET').then((resp)=>{
          console.log('master_kode_barang/level/3/'+kode_golongan+'.'+kode_bidang,resp);
          let result = [];
          result.push({label:'Pilih',value:'00',name:'kode_kelompok',kode_kelompok:kode_kelompok,level_kode:3})
          resp.map((item)=>{
            result.push({
              label:item.nama_master_kode_barang
              ,value:item.kode_barang
              ,name:'kode_kelompok'
              ,kode_golongan:item.kode_golongan
              ,kode_bidang:item.kode_bidang
              ,kode_kelompok:item.kode_kelompok
              ,kode_sub_kelompok:item.kode_sub_kelompok
              ,kode_sub_sub_kelompok:item.kode_sub_sub_kelompok
              ,level_kode:3
              ,id:item.id
            })
          })
          setKelompok(result);
        });    
        break;
      case 3:
        kelompok.map((item,key)=>{
          if (item.id===id) {
            setInputs(values => ({...values, ['kelompok_id']: key}))
          }
        })
        setInputs(values => ({...values, ['kode_sub_kelompok']: '00'}))
        setInputs(values => ({...values, ['kode_sub_sub_kelompok']: '000'}))

        setInputs(values => ({...values, ['sub_kelompok_id']: 0}))
        setInputs(values => ({...values, ['sub_sub_kelompok_id']: 0}))
        setInputs(values => ({...values, [name]: kode_kelompok}))

        setSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_kelompok',kode_sub_kelompok:'00',level_kode:4          
        }])     
        setSubSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_sub_kelompok',kode_sub_sub_kelompok:'000',level_kode:5          
        }])     

        UseAPI('master_kode_barang/level/4/'+kode_golongan+'.'+kode_bidang+'.'+kode_kelompok,{},'GET').then((resp)=>{
          console.log('master_kode_barang/level/4/'+kode_golongan+'.'+kode_bidang+'.'+kode_kelompok,resp);
          let result = [];
          result.push({label:'Pilih',value:'00',name:'kode_sub_kelompok',kode_sub_kelompok:kode_sub_kelompok,level_kode:4})
          resp.map((item)=>{
            result.push({
              label:item.nama_master_kode_barang
              ,value:item.kode_barang
              ,name:'kode_sub_kelompok'
              ,kode_golongan:item.kode_golongan
              ,kode_bidang:item.kode_bidang
              ,kode_kelompok:item.kode_kelompok
              ,kode_sub_kelompok:item.kode_sub_kelompok
              ,kode_sub_sub_kelompok:item.kode_sub_sub_kelompok
              ,level_kode:4
              ,id:item.id
            })
          })
          setSubKelompok(result);
        });    
        break;
      case 4:
        sub_kelompok.map((item,key)=>{
          if (item.id===id) {
            setInputs(values => ({...values, ['sub_kelompok_id']: key}))
          }
        })
        setInputs(values => ({...values, ['kode_sub_sub_kelompok']: '000'}))

        setInputs(values => ({...values, ['sub_sub_kelompok_id']: 0}))
        setInputs(values => ({...values, [name]: kode_sub_kelompok}))

        setSubSubKelompok([{
          label:'Pilih',value:'00',name:'kode_sub_sub_kelompok',kode_sub_sub_kelompok:'000',level_kode:5          
        }])     

        UseAPI('master_kode_barang/level/5/'+kode_golongan+'.'+kode_bidang+'.'+kode_kelompok+'.'+kode_sub_kelompok,{},'GET').then((resp)=>{
          console.log('master_kode_barang/level/5/'+kode_golongan+'.'+kode_bidang+'.'+kode_kelompok+'.'+kode_sub_kelompok,resp);
          let result = [];
          // if (resp.length > 0) {
            result.push({label:'Pilih',value:'000',name:'kode_sub_sub_kelompok',kode_sub_sub_kelompok:kode_sub_sub_kelompok,level_kode:5})
            resp.map((item)=>{
              result.push({
                label:item.nama_master_kode_barang
                ,value:item.kode_barang
                ,name:'kode_sub_sub_kelompok'
                ,kode_golongan:item.kode_golongan
                ,kode_bidang:item.kode_bidang
                ,kode_kelompok:item.kode_kelompok
                ,kode_sub_kelompok:item.kode_sub_kelompok
                ,kode_sub_sub_kelompok:item.kode_sub_sub_kelompok
                ,level_kode:5
                ,id:item.id  
              })
            })
            setSubSubKelompok(result);
          // }
        });    
        break;
      case 5:
        sub_sub_kelompok.map((item,key)=>{
          if (item.id===id) {
            setInputs(values => ({...values, ['sub_sub_kelompok_id']: key}))
          }
        })
        setInputs(values => ({...values, [name]: kode_sub_sub_kelompok}))

        break;
    }
  }

  const handleSave = (e) => {    
    e.preventDefault();
    console.log("tanah_id",tanah_id);
    setInputs(values => ({...values, ['tanah_id']: parseInt(tanah_id)}))
    console.log("inputs",inputs);
    let bisa_save = 1;
    Object.entries(inputs).map(([key,val])=>{
      switch (key) {
        case 'golongan_id':
        case 'bidang_id':
        case 'kelompok_id':
        case 'sub_kelompok_id':
        case 'sub_sub_kelompok_id':
          if (val === 0) {
            bisa_save = 0;
          //   Swal.fire({
          //     title: 'Gagal',
          //     text: 'Kelompok Aset wajib di lengkapi',
          //     icon: "error",
          //   }); 
    
          }
          break;      
      }
    })

    if (bisa_save) {
      Swal.fire({
        title: "Anda yakin ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          try {
            return UseAPI('bangunan',inputs,'POST').then(async (resp)=>{
              if (resp.status === 'er') {
                return Swal.showValidationMessage(`
                  ${JSON.stringify(await resp)}
                `);
              }
              return resp;
            });
  
            // if (!response.ok) {
            //   return Swal.showValidationMessage(`
            //     ${JSON.stringify(await response.json())}
            //   `);
            // }
            // return response.json();
          } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Konfirmasi',
            text: result.value.pesan,
            icon: "success",
          }); 
        }
      });         

    } else {
        Swal.fire({
          title: 'Gagal',
          text: 'Kelompok Aset wajib di lengkapi',
          icon: "error",
        });       
    }


 
  }
  useEffect(()=>{
    UseAPI('tanah',{},'GET').then((resp)=>{
      setTanah(resp.data)
      console.log("tanah",resp);
    });  
    UseAPI('master_kode_barang/level/1',{},'GET').then((resp)=>{      
      let golongan_arr = [];
      golongan_arr.push({label:'Pilih',value:'00',name:'kode_golongan',kode_golongan:'00',level_kode:1})
      resp.map((item)=>{
        golongan_arr.push({
          label:item.nama_master_kode_barang
          ,value:item.kode_golongan
          ,name:'kode_golongan'
          ,kode_golongan:item.kode_golongan
          ,kode_bidang:item.kode_bidang
          ,kode_kelompok:item.kode_kelompok
          ,kode_sub_kelompok:item.kode_sub_kelompok
          ,kode_sub_sub_kelompok:item.kode_sub_sub_kelompok
          ,level_kode:1
          ,id:item.id
        })
      })
      setGolongan(golongan_arr);
    })

  },[])

  const yesNoOptions = [
    { value: "Ya", label: "Ya" }
    ,{ value: "Tidak", label: "Tidak" }
  ];  

  return (
    <Fragment>
      <PageTitle
        motherMenu="Bangunan"
        activeMenu="Tambah"
        pageContent="Tambah"
      />

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Data Bangunan</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Nama Bangunan</label>
                    <div className="col-sm-9">
                      <input
                        defaultValue={inputs.nama_bangunan}
                        name={'nama_bangunan'}
                        type="text"
                        className="form-control"
                        placeholder={'Nama Bangunan'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Luas</label>
                    <div className="col-sm-9">
                      <input
                        defaultValue={inputs.luas_bangunan}
                        name={'luas_bangunan'}
                        type="text"
                        className="form-control"
                        placeholder={'Luas Bangunan'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>  
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Kelompok Aset</h4>
              <span>
                {`
                  ${inputs.kode_golongan}
                  .${inputs.kode_bidang}
                  .${inputs.kode_kelompok}
                  .${inputs.kode_sub_kelompok}
                  .${inputs.kode_sub_sub_kelompok}
                `}
              </span>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Golongan</label>
                    <div className="col-sm-9">
                      <Select
                          value={golongan[inputs.golongan_id]}
                          onChange={handleChangeKodeBarang}
                          options={golongan}
                          name={'kode_golongan'}
                          id={'kode_golongan'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Bidang</label>
                    <div className="col-sm-9">
                      <Select
                          value={bidang[inputs.bidang_id]}
                          onChange={handleChangeKodeBarang}
                          options={bidang}
                          name={'kode_bidang'}
                          id={'kode_bidang'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Kelompok</label>
                    <div className="col-sm-9">
                      <Select
                          value={kelompok[inputs.kelompok_id]}
                          onChange={handleChangeKodeBarang}
                          options={kelompok}
                          name={'kode_kelompok'}
                          id={'kode_kelompok'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Sub Kelompok</label>
                    <div className="col-sm-9">
                      <Select
                          value={sub_kelompok[inputs.sub_kelompok_id]}
                          onChange={handleChangeKodeBarang}
                          options={sub_kelompok}
                          name={'kode_sub_kelompok'}
                          id={'kode_sub_kelompok'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Sub Sub Kelompok</label>
                    <div className="col-sm-9">
                      <Select
                          value={sub_sub_kelompok[inputs.sub_sub_kelompok_id]}
                          onChange={handleChangeKodeBarang}
                          options={sub_sub_kelompok}
                          name={'kode_sub_sub_kelompok'}
                          id={'kode_sub_sub_kelompok'}
                      />                                         
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
        
      </div>      

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Data Penggunaan</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Penggunaan </label>
                    <div className="col-sm-9">
                      <input
                        value={inputs.penggunaan}
                        name="penggunaan"
                        type="text"                        
                        className="form-control"
                        placeholder="Penggunaan"
                        onChange={handleChange}
                      />

                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Pengelola </label>
                    <div className="col-sm-9">
                      <input
                        value={inputs.pengelola}
                        name="pengelola"
                        type="text"                        
                        className="form-control"
                        placeholder="Pengelola"
                        onChange={handleChange}
                      />

                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
        
      </div>      
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Data Tambahan</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Harga Perolehan </label>
                    <div className="col-sm-9">
                      <input
                        value={inputs.harga_perolehan}
                        name="harga_perolehan"
                        type="number"                        
                        className="form-control"
                        placeholder="Harga Perolehan"
                        onChange={handleChange}
                      />
                    </div>                      
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Waktu Perolehan </label>
                    <div className="col-sm-9">
                      <input
                        value={inputs.waktu_perolehan}
                        name="waktu_perolehan"
                        type="date"                        
                        className="form-control"
                        placeholder="Waktu Perolehan"
                        onChange={handleChange}
                      />
                    </div>                      
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Keterangan</label>
                    <div className="col-sm-9">
                      <input
                        value={inputs.keterangan_bangunan}
                        name="keterangan_bangunan"
                        type="text"                        
                        className="form-control"
                        placeholder="Keterangan"
                        onChange={handleChange}
                      />
                    </div>                      
                  </div>

              </div>
            </div>
          </div>
        </div>
        
      </div>      
      <div className="row">
                  {/* <div className="col-xl-12 col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Data GPS</h4>
                      </div>
                      <div className="card-body">
                        <p>GPS</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary" onClick={handleSave}>
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
        
      </div>      

    </Fragment>
  );
};

export default BangunanTambahForm;
