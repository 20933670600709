import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";


const KodeBarangTambah = () => {
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  const [tanah, setTanah] = useState([]);
  const [bangunan, setBangunan] = useState([]);
  const [ruangan, setRuangan] = useState([]);
  const [master_kode_barang, setMasterKodeBarang] = useState([]);
  const [kodeLokasi, setKodeLokasi] = useState([
    '00','00','00','00','00','0000'
  ])
  const [kodeAset, setKodeAset] = useState([
    '00','00','00','00','00','0000'
  ])

  // const [kodeAset, setKodeAset] = useState([
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xxxx'}
  // ])
  
  // const [inputs, setInputs] = useState({
  //   pemegang_hak: "",
  //   nomor_sertifikat: "",
  //   nib: "",
  //   pemegang_hak_sebelum: "",
  //   jenis_sertifikat_id: "",
  //   status_tanah_id: "",
  //   luas: "",
  //   alamat: "",
  //   lokasi_sertifikat_disimpan: "",
  //   dokumen_sertifikat: "",
  //   dokumen_tanah: "",
  //   lat: "",
  //   lng: "",
  //   cara_perolehan_id: "",
  //   status_fasum: "",
  //   penggunaan:'',
  //   kode_wilayah:''
  // status_sengketa:'',
  // harga_perolehan:0,
  // waktu_perolehan:Date()
    
  // });
  let tanggal = new Date();
  const tanggal_sekarang = `${tanggal.getFullYear()}-${(tanggal.getMonth()+1)}-${tanggal.getDate()}`;
  // console.log(tanggal_sekarang);
  const [inputs, setInputs] = useState({
    kode_bidang: "00",
    kode_golongan:"00",
    kode_kelompok:"00",
    kode_sub_kelompok:"00",
    kode_sub_sub_kelompok:"???",
  });
  const [caraPerolehan, setCaraPerolehan] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [golongan, setGolongan] = useState({});
  const [bidang, setBidang] = useState({});
  const [kelompok, setKelompok] = useState({});
  const [sub_kelompok, setSubKelompok] = useState({});
  const [sub_sub_kelompok, setSubSubKelompok] = useState({});

  let endpoint = 'cara_perolehan';
  const updateKodeAset = (index, newValue) => {
    setKodeAset(prevItems =>
      prevItems.map((item, i) => (i === index ? newValue : item)) 
    );
  };
  const updateKodeLokasi = (index, newValue) => {
    setKodeLokasi(prevItems =>
      prevItems.map((item, i) => (i === index ? newValue : item)) 
    );
  };

  const handleChange = (event) => {
    console.log(event);
    if (!event.target) {
      setInputs(values => ({...values, [event.name]: event.value}))
    } else {      
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
        case 'pemegang_hak':
          setInputs(values => ({...values, [name]: value}))
          break;
        case 'dokumen_sertifikat':
        case 'dokumen_tanah':
        case 'gambar_barang':
          setInputs(values => ({...values, [name]: event.target.files[0]}))
          break;
        default:
          setInputs(values => ({...values, [name]: value}))
          break;
      }
    }
  }
  
  const handleChangeKodeBarang = (event) => {
    console.log("event",event);    
    let level_kode = event.level_kode;
    let  kode_barang = event.kode_barang;
    // let kode_dagri = event.kode_dagri;
    // console.log(event);
    handleChange(event);
    switch (level_kode) {
      case 1:
        UseAPI('master_kode_barang/level/1',{},'GET').then((resp)=>{
          console.log("master_kode_barang/level/1/",resp);
          let result = [];
          resp.map((item)=>{
            result.push({label:item.nama_master_kode_barang,value:item.kode_barang,name:'kode_golongan',kode_barang:item.kode_golongan,level_kode:3})
          })
          setGolongan(result);
        });    
        break;
      case 2:
        UseAPI('master_kode_barang/level/2/'+kode_barang,{},'GET').then((resp)=>{
          console.log("master_kode_barang/level/2/",resp);
          let result = [];
          resp.map((item)=>{
            result.push({label:item.nama_master_kode_barang,value:item.kode_bidang,name:'kode_bidang',kode_barang:item.kode_golongan+'.'+item.kode_bidang,level_kode:3})
          })
          console.log("push_kode_bidang",result);
          if (result.length > 0) {
            setBidang(result);
          } else {
            setInputs(values => ({...values, ['kode_bidang']: '00'}))
            setBidang({});
          }
        });      
        break;
      case 3:
        UseAPI('master_kode_barang/level/3/'+kode_barang,{},'GET').then((resp)=>{
          console.log("master_kode_barang/level/3/",resp);
          let result = [];
          resp.map((item)=>{
            result.push({label:item.nama_master_kode_barang,value:item.kode_kelompok,name:'kode_kelompok',kode_barang:item.kode_golongan+'.'+item.kode_bidang+'.'+item.kode_kelompok,level_kode:4})
          })
          console.log("push_kode_kelompok",result);
          if (result.length > 0) {
            setKelompok(result);
            // setInputs(values => ({...values, ['kode_kelompok']: resp.kode_kelompok}))
          } else {
            setInputs(values => ({...values, ['kode_kelompok']: '00'}))
            setKelompok({});
          }

        });      
        break;
      case 4:
        UseAPI('master_kode_barang/level/4/'+kode_barang,{},'GET').then((resp)=>{
          console.log("master_kode_barang/level/4/",resp);
          let result = [];
          if (resp.length) {
            console.log("kode_sub_kelompok");
            resp.map((item)=>{
              result.push({label:item.nama_master_kode_barang,value:item.kode_sub_kelompok,name:'kode_sub_kelompok',kode_barang:item.kode_golongan+'.'+item.kode_bidang+'.'+item.kode_kelompok+'.'+item.kode_sub_kelompok,level_kode:5})
            })
            setSubKelompok(result);
            // setInputs(values => ({...values, ['kode_sub_kelompok']: resp.kode_sub_kelompok}))
          } else {
            setInputs(values => ({...values, ['kode_sub_kelompok']: '00'}))
            setSubKelompok({});

          }           

        });      
        break;
      case 5:
        UseAPI('master_kode_barang/level/5/'+kode_barang,{},'GET').then((resp)=>{
          console.log("master_kode_barang/level/5/"+kode_barang,resp);
          let result = [];
          if (resp.length) {
            console.log("ada");
            resp.map((item)=>{
              result.push({label:item.nama_master_kode_barang,value:item.kode_sub_sub_kelompok,name:'kode_sub_sub_kelompok',kode_barang:item.kode_golongan+'.'+item.kode_bidang+'.'+item.kode_kelompok+'.'+item.kode_sub_kelompok+'.'+item.kode_sub_sub_kelompok,level_kode:6})
            })
            setSubSubKelompok(result);
          } else {
            console.log("kosong");
            setInputs(values => ({...values, ['kode_sub_sub_kelompok']: '000'}))
            setSubSubKelompok({});
          }


        });      
        break;
    }
  }
   
  const handleSave = (e) => {        
    e.preventDefault();
    console.log("inputs",inputs);
    console.log("selectedoption",selectedOption);
    Swal.fire({
      title: "Anda yakin ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Simpan",
      cancelButtonText: "Batal",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        try {
          let bisa_save = 1;
          Object.entries(inputs).map(([key,val])=>{
            console.log(key,val);
            switch (key) {
              case 'kondisi_id':
              case 'cara_perolehan_id':
              case 'duplikasi':
              case 'nama_barang':
                if (val == '0' || val == 0 || val == '') {
                  bisa_save = 0;
                }
                break;
            }
          })          
          console.log("bisa_save",bisa_save);
          if (bisa_save) {
            return UseAPI('master_kode_barang',inputs,'POST').then(async (resp)=>{
              if (resp.status === 'er') {
                return Swal.showValidationMessage(`
                  ${JSON.stringify(await resp)}
                `);
              }
              return resp;
            });
          } else {
                return Swal.showValidationMessage(`
                  Lengkapi Data
                `);

          }
          // if (!response.ok) {
          //   return Swal.showValidationMessage(`
          //     ${JSON.stringify(await response.json())}
          //   `);
          // }
          // return response.json();
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Konfirmasi',
          text: result.value.pesan,
          icon: "success",
        }); 
      }
    });    
  }
  useEffect(()=>{
    UseAPI('cara_perolehan',{},'GET').then((resp)=>{
      let cara_perolehan = [];
      cara_perolehan.push({label:'Pilih',value:'0',name:'cara_perolehan_id'})
      resp.map((item)=>{
        cara_perolehan.push({label:item.nama_cara_perolehan,value:item.id,name:'cara_perolehan_id'})
      })
      setCaraPerolehan(cara_perolehan);
    });  
    UseAPI('bangunan',{},'GET').then((resp)=>{
      let bangunan_arr = [];
      resp.map((item)=>{
        bangunan_arr.push({label:item.nama_bangunan,value:item.id,name:'bangunan_id'})
      })
      setBangunan(bangunan_arr);
      console.log("bangunan",resp);
    });  
    UseAPI('ruangan',{},'GET').then((resp)=>{

      let ruangan_arr = [];
      resp.map((item)=>{
        ruangan_arr.push({label:item.nama_bangunan+' | '+item.nama_ruangan,value:item.id,name:'ruangan_id'})
      })
      setRuangan(ruangan_arr);
    });  
    UseAPI('master_kode_barang/level/1',{},'GET').then((resp)=>{
      let golongan_arr = [];
      resp.map((item)=>{
        golongan_arr.push({label:item.nama_master_kode_barang,value:item.kode_golongan,name:'kode_golongan',kode_barang:item.kode_golongan,level_kode:2})
      })
      setGolongan(golongan_arr);
      // let master_kode_barang_arr = [];
      // let golongan = []
      // let bidang = [] 
      // let kelompok = []
      // let sub_kelompok = []
      // let sub_sub_kelompok = []
      // let kode_barang = {}
      // resp.map((item)=>{
      //   if (item.kode_bidang === '00') {
      //     golongan.push({label:item.nama_master_kode_barang,value:item.id,name:'master_kode_barang_id'})
      //   } else if (item.kode_kelompok === '00') { 
      //     bidang.push({label:item.nama_master_kode_barang,value:item.id,name:'master_kode_barang_id'})
      //   } else if (item.kode_sub_kelompok === '00') {
      //     kelompok.push({label:item.nama_master_kode_barang,value:item.id,name:'master_kode_barang_id'})
      //   } else if (item.kode_sub_sub_kelompok === '000') {
      //     sub_kelompok.push({label:item.nama_master_kode_barang,value:item.id,name:'master_kode_barang_id'})
      //   } else {
      //     sub_sub_kelompok.push({label:item.nama_master_kode_barang,value:item.id,name:'master_kode_barang_id'})
      //   }
      //   Object.assign(kode_barang,{golongan:golongan})
      //   Object.assign(kode_barang,{bidang:bidang})
      //   Object.assign(kode_barang,{kelompok:kelompok})
      //   Object.assign(kode_barang,{sub_kelompok:sub_kelompok})
      //   Object.assign(kode_barang,{sub_sub_kelompok:sub_sub_kelompok}) 
      // })
      // setMasterKodeBarang(kode_barang);
      // console.log(master_kode_barang);
    });  

  },[])

  const groupStyles = {
    border: `2px dotted`,
    borderRadius: "5px",
    background: "#f2fcff",
  };
  
  const yesNoOptions = [
    { value: "Ya", label: "Ya" }
    ,{ value: "Tidak", label: "Tidak" }
  ];  

  return (
    <Fragment>
      <PageTitle
        motherMenu="Kode Barang"
        activeMenu="Tambah"
        pageContent="Tambah"
      />

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Kelompok Aset</h4>
              <span style={{fontSize:'1.5rem'}}>
                {`${inputs.kode_golongan}.${inputs.kode_bidang}.${inputs.kode_kelompok}.${inputs.kode_sub_kelompok}.${inputs.kode_sub_sub_kelompok}`}
              </span>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Nama Barang</label>
                    <div className="col-sm-9">
                      <input
                        name="nama_master_kode_barang"
                        type="text"
                        className="form-control"
                        placeholder="Nama Barang"
                        onChange={handleChange}
                      />

                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Golongan</label>
                    <div className="col-sm-9">
                      <Select
                          value={golongan[inputs.kode_golongan]}
                          onChange={handleChangeKodeBarang}
                          options={golongan}
                          name={'kode_golongan'}
                          id={'kode_golongan'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Bidang</label>
                    <div className="col-sm-9">
                      <Select
                          value={bidang[inputs.kode_bidang]}
                          onChange={handleChangeKodeBarang}
                          options={bidang}
                          name={'kode_bidang'}
                          id={'kode_bidang'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Kelompok</label>
                    <div className="col-sm-9">
                      <Select
                          value={kelompok[inputs.kode_kelompok]}
                          onChange={handleChangeKodeBarang}
                          options={kelompok}
                          name={'kode_kelompok'}
                          id={'kode_kelompok'}
                      />                                         
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Sub Kelompok</label>
                    <div className="col-sm-9">
                      <Select
                          value={sub_kelompok[inputs.kode_sub_kelompok]}
                          onChange={handleChangeKodeBarang}
                          options={sub_kelompok}
                          name={'kode_sub_kelompok'}
                          id={'kode_sub_kelompok'}
                      />                                         
                    </div>
                  </div>
                  {/* <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Sub Sub Kelompok</label>
                    <div className="col-sm-9">
                      <Select
                          value={sub_sub_kelompok[inputs.kode_sub_sub_kelompok]}
                          onChange={handleChangeKodeBarang}
                          options={sub_sub_kelompok}
                          name={'kode_sub_sub_kelompok'}
                          id={'kode_sub_sub_kelompok'}
                      />                                         
                    </div>
                  </div> */}

              </div>
            </div>
          </div>
        </div>
        
      </div>      
   
      <div className="row">
                  {/* <div className="col-xl-12 col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Data GPS</h4>
                      </div>
                      <div className="card-body">
                        <p>GPS</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary" onClick={handleSave}>
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
        
      </div>      

    </Fragment>
  );
};

export default KodeBarangTambah;
