import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import DonutChart from './Dashboard/DonutChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faChair, faLandmark, faMap, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Doughnut } from "react-chartjs-2";
import { UseAPI } from '../../../services/UseAPI';

const Home = () => {
	const [summary,setSummary] = useState({})	
	const colors = [ 
		'rgb(238, 181, 125)',
		'rgb(47, 173, 148)',
		'rgb(78, 144, 142)',
		'rgb(59, 172, 217)',
		'rgb(95, 240, 148)',
		'rgb(123, 189, 231)',
		'rgb(212, 202, 67)',
		'rgb(84, 200, 97)',
		'rgb(88, 225, 238)',
		'rgb(117, 173, 127)',
	];
	const getColors = (jml) => {
		let this_colors = []
		colors.map((item,key)=>{
			if (key < jml) {
				this_colors.push(item)
			}
		})
		return this_colors;
	}
    const buatDonat = (labels,datasets) => {
		return {
			labels: labels,
			datasets: [
			  {
				label: '# Tanah',
				data: datasets,
				backgroundColor: getColors(labels && labels.length),
				borderColor: getColors(labels && labels.length),
				borderWidth: 1,
			  },
			],		
		}
    };

    const options = {
      width: 100,
      responsive: true,
      maintainAspectRatio: false,
      tooltips: { enabled: false },
      hover: { mode: null },
	  plugins: {
		legend: {
			display:true,
			position:'right',
			align:'top',
			onClick: (evt, legendItem, legend) => {

				console.log(evt,legendItem,legend);
			}
		}
	  }
    };

	useEffect(()=>{
		UseAPI('home',{},'GET').then((resp)=>{
			console.log(resp);
			setSummary(resp);
			console.log("tanah",resp);
		});  	
		// UseAPI('bangunan',{},'GET').then((resp)=>{
		// 	console.log("bangunan",resp);
		// 	setBangunan(resp.data);
		// });  	
		// UseAPI('barang',{},'GET').then((resp)=>{
		// 	console.log("barang",resp);
		// 	setBarang(resp.data);
		// });  	
		// UseAPI('kendaraan',{},'GET').then((resp)=>{
		// 	console.log("kerndaraan",resp);
		// 	setKendaraan(resp.data);
		// });  	
	},[])

	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-3 col-sm-6">
							<div className="card overflow-hidden">
								<div className="card-header border-0">
									<div className="d-flex">
										<span className="justify-content-center align-self-center mt-2">
											<FontAwesomeIcon icon={ faMapLocationDot } className='fa-3x' />
										</span>
										<div className="invoices">
											<h4>{summary.tanah && summary.tanah.jumlah}</h4>
											<span>Tanah</span>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="card overflow-hidden">
								<div className="card-header border-0">
									<div className="d-flex">
										<span className="justify-content-center align-self-center mt-2">
											<FontAwesomeIcon icon={ faLandmark } className='fa-3x' />
										</span>
										<div className="invoices">
											<h4>{summary.bangunan && summary.bangunan.jumlah}</h4>
											<span>Bangunan</span>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="card overflow-hidden">
								<div className="card-header border-0">
									<div className="d-flex">
										<span className="justify-content-center align-self-center mt-2">
											<FontAwesomeIcon icon={ faChair } className='fa-3x' />
										</span>
										<div className="invoices">
											<h4>{summary.barang && summary.barang.jumlah}</h4>
											<span>Barang</span>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						<div className="col-xl-3 col-sm-6">
							<div className="card overflow-hidden">
								<div className="card-header border-0">
									<div className="d-flex">
										<span className="justify-content-center align-self-center mt-2">
											<FontAwesomeIcon icon={ faCar } className='fa-3x' />
										</span>
										<div className="invoices">
											<h4>{summary.kendaraan && summary.kendaraan.jumlah}</h4>
											<span>Kendaraan</span>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>	
			<div className='row'>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div>
									<h4 className="mb-2 font-w700">Tanah</h4>
									<span className="fs-12">{summary.tanah && summary.tanah.jumlah} aset</span>
								</div>
								<span>
									<i className='fas fa-2x fa-medal'></i>
								</span>
							</div>
							<div className="card-body">	
								<div className="row">
									<div className="col-sm-12 col-lg-12">
									<Doughnut
										options={options}
										data={buatDonat(summary.tanah && summary.tanah.grafik.labels,summary.tanah && summary.tanah.grafik.datasets)}
									/>

									</div>
								</div>							

							</div>
							<div className="card-footer border-0 pt-0">
								<Link to={"/bangunan/?from=0"} className="btn btn-primary d-block btn-lg text-uppercase">Lihat Semua</Link>
							</div>
						</div>


						</div>
					</div>
				</div>				
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div>
									<h4 className="mb-2 font-w700">Bangunan</h4>
									<span className="fs-12">{summary.bangunan && summary.bangunan.jumlah} aset</span>
								</div>
								<span>
									<i className='fas fa-2x fa-medal'></i>
								</span>
							</div>
							<div className="card-body">	
								<div className="row">
									<div className="col-sm-12 col-lg-12">
									<Doughnut
										options={options}
										data={buatDonat(summary.bangunan && summary.bangunan.grafik.labels,summary.bangunan && summary.bangunan.grafik.datasets)}
									/>

									</div>
								</div>							

							</div>
							<div className="card-footer border-0 pt-0">
								<Link to={"/bangunan/?from=0"} className="btn btn-primary d-block btn-lg text-uppercase">Lihat Semua</Link>
							</div>
						</div>


						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div>
									<h4 className="mb-2 font-w700">Barang</h4>
									<span className="fs-12">{summary.barang && summary.barang.jumlah} aset</span>
								</div>
								<span>
									<i className='fas fa-2x fa-medal'></i>
								</span>
							</div>
							<div className="card-body">	
								<div className="row">
									<div className="col-sm-12 col-lg-12">
									<Doughnut
										options={options}
										data={buatDonat(summary.barang && summary.barang.grafik.labels,summary.barang && summary.barang.grafik.datasets)}
									/>

									</div>
								</div>							

							</div>
							<div className="card-footer border-0 pt-0">
								<Link to={"/barang/?from=0"} className="btn btn-primary d-block btn-lg text-uppercase">Lihat Semua</Link>
							</div>
						</div>


						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div>
									<h4 className="mb-2 font-w700">Kendaraan</h4>
									<span className="fs-12">{summary.kendaraan && summary.kendaraan.jumlah} aset</span>
								</div>
								<span>
									<i className='fas fa-2x fa-medal'></i>
								</span>
							</div>
							<div className="card-body">	
								<div className="row">
									<div className="col-sm-12 col-lg-12">
									<Doughnut
										options={options}
										data={buatDonat(summary.kendaraan && summary.kendaraan.grafik.labels,summary.barang && summary.kendaraan.grafik.datasets)}
									/>

									</div>
								</div>							

							</div>
							<div className="card-footer border-0 pt-0">
								<Link to={"/kendaraan"} className="btn btn-primary d-block btn-lg text-uppercase">Lihat Semua</Link>
							</div>
						</div>


						</div>
					</div>
				</div>


			</div>

		</>
	)
}
export default Home;