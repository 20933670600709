import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { UseAPI } from "../../../../services/UseAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSave } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UserEdit = () => {
  const { id }  = useParams();
  console.log(id);
  const [data, setData] = useState([])
  const [inputs, setInputs] = useState({});
  useEffect(()=>{
    UseAPI('users/'+id,{},'GET').then((resp)=>{
      console.log(resp);
      setData(resp);
    });  
  },[])
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }  
  const handleSave = (e) => {
    e.preventDefault();
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UseAPI(`users/${id}`,inputs,'POST').then((resp)=>{
          console.log(resp);
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        });
      }
    })
  }
  return (
    <Fragment>
      <PageTitle
        motherMenu="Kategori"
        activeMenu="Detail"
        pageContent="Kategori"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Nama</label>
                    <div className="col-sm-9">
                      <input
                        name="first_name"
                        type="text"
                        className="form-control"
                        placeholder="Nama"
                        defaultValue={data[0] && data[0].nama}
                        onChange={handleChange}
                        // value={data[0] && data[0].category_name}
                        // onChange={(e) => e.target.value}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      
    </Fragment>
  );
};

export default UserEdit;
