import React,{useState} from 'react';
import {Link} from 'react-router-dom';


const Pengaturan = () =>{
	return(
		<>
            Ini halaman Pengaturan
		</>
	)
}

export default Pengaturan;