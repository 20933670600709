export function UseAPI(type, userData, method) {
    let BaseURL = 'https://assetmu.apps.unismuh.ac.id/api/';
    return new Promise((resolve, reject) =>{    
        switch (method) {            
            case 'POST':
                var formdata = new FormData();
                Object.entries(userData).map(([key, value]) =>
                    formdata.append(key,value)
                );                
                // formdata.append('idToken',(localStorage.getItem('userDetails')));

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                };
                
                fetch(BaseURL+type, requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
                break;        
            case 'DELETE':
                var urlencoded = new URLSearchParams();
                var requestOptions = {
                    method: 'DELETE',
                    body: urlencoded,
                    redirect: 'follow',
                };
                
                fetch(BaseURL+type, requestOptions)
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => reject(error));
                break;        
            case 'GET':
                fetch(BaseURL+type, {
                    method: 'GET'
                })
                .then((response) => response.json())
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });                        
                break;
        }
    });
}