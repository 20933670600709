import React,{ useEffect, useMemo, useState } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { KOLOM } from './Columns';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { UseAPI } from '../../../../services/UseAPI';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export const KodeBarang = () => {
  const [sourcedata, setSourceData] = useState([]);
	const columns = useMemo( () => KOLOM, [] )

  useEffect(() => {
    let kode_barang = [];
    UseAPI('master_kode_barang/table',{},'GET').then((resp)=>{
      resp.map((item)=>{
        kode_barang.push(
          {
            id:item.id,
            kode_barang:item.kode_barang,
            nama_master_kode_barang:item.nama_master_kode_barang
          }
        )
      })
      console.log(resp);
      setSourceData(kode_barang);
    })
       
  }, []);  
	
	const data = useMemo( () => sourcedata, [sourcedata] )
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
	const {globalFilter, pageIndex} = state
	
	return(
		<>
			<PageTitle activeMenu="Daftar" motherMenu="Kode Barang" />
			<div className="card">
        <div className="card-header">
          <h4 className="card-title">Kode Barang</h4>
          <Button className="me-2" variant="primary btn-rounded" as={Link} to={'/kode_barang/tambah'}>
            <span className="btn-icon-start text-primary">
              <i className="fa fa-plus color-info" />
            </span>
            Baru
          </Button>          
          {/* <Link to={"#"} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faPlus} /> Baru</Link> */}
        </div>
				<div className="card-body">
					<div className="table-responsive">
						<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            {sourcedata && (
						<table {...getTableProps()} className="table dataTable display">
							<thead>
							   {headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
												{column.canFilter ? column.render('Filter') : null}
											</th>
										))}
									</tr>
							   ))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
							
								{page.map((row) => {
									prepareRow(row)
									return(
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
            )}
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
									className="ml-2"
									defaultValue={pageIndex + 1} 
									onChange = {e => { 
										const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
										gotoPage(pageNumber)
									} } 
								/>
							</span>
						</div>
						<div className="text-center mb-3">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
	
}
export default KodeBarang;