import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Link } from "react-router-dom";

const Header = ({ onNote }) => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName;
  return ( 
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
                >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>            
            <ul className="navbar-nav header-right">
              <li className="nav-item invoices-btn">
                <Link to={"/tryout"} className="btn btn-primary ms-5"><FontAwesomeIcon icon={ faPlus } className="me-3" />ASSET</Link>
              </li>				
            </ul>
          </div>  
        </nav>
      </div>
    </div>
  );
};

export default Header;
