import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { UseAPI } from "../../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";


const CaraPerolehanTambah = () => {
  const [data, setData] = useState([])
  const [inputs, setInputs] = useState({});
  let endpoint = 'cara_perolehan';
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }  
  const handleSave = (e) => {
    e.preventDefault();
    swal({
      title: "Anda yakin ?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        UseAPI(endpoint,inputs,'POST').then((resp)=>{
          console.log(resp);
          if (resp.status === 'ok') {
            setData(resp.data);
            swal(resp.pesan, {
              icon: "success",
            });    
          } else {
            swal(resp.pesan, {
              icon: "danger",
            });    
          }
        });
      }
    })
  }
  return (
    <Fragment>
      <PageTitle
        motherMenu="Cara Perolehan"
        activeMenu="Tambah"
        pageContent="Tambah"
      />

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tambah</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleSave}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Deskripsi</label>
                    <div className="col-sm-9">
                      <input
                        name="nama_cara_perolehan"
                        type="text"
                        className="form-control"
                        placeholder="Deskripsi"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />Simpan
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>      

    </Fragment>
  );
};

export default CaraPerolehanTambah;
