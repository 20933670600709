import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'react-bootstrap';

export const KOLOM = [
	{
		Header: '#',
		Cell: ({row}) => (
			<>
				{parseInt(row.id)+1}
			</>
		)
	}	
	,{
		Header:'Nama Bangunan',
		accessor:'nama_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Nama Ruangan',
		accessor:'nama_ruangan',
		Filter:ColumnFilter
	}
	,{
		Header:'Kode Ruangan',
		accessor:'kode_ruangan',
		Filter:ColumnFilter
	}
	,{
		Header: 'Aksi',
		Cell: ({ row, handleRowAction, handleRowActionDelete }) => (
			<>
				<ButtonGroup className="me-2" aria-label="First group">
				<Button variant="primary" onClick={() => handleRowAction(row.original)} size='sm'>
							<FontAwesomeIcon icon={ faEdit } />
				</Button>
				<Button variant="danger" onClick={() => handleRowActionDelete(row.original)} size='sm'>
							<FontAwesomeIcon icon={ faTrash } />
				</Button>
				</ButtonGroup>

			</>
		),
	}
]
export const KOLOM_BANGUNAN = [
	{
		Header: '#',
		Cell: ({row}) => (
			<>
				{parseInt(row.id)+1}
			</>
		)
	}	
	,{
		Header:'Kode Lokasi',
		accessor:'kode_lokasi_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Kode Aset',
		accessor:'kode_aset_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Nama Bangunan',
		accessor:'nama_bangunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Jumlah Ruangan',
		accessor:'total_ruangan',
		Filter:ColumnFilter
	}
	,{
		Header: 'Aksi',
		Cell: ({ row, handleRowAction, handleRowActionDelete }) => (
			<>
				<ButtonGroup className="me-2" aria-label="First group">
				<Button variant="primary" onClick={() => handleRowAction(row.original)} size='sm'>
					Pilih
				</Button>
				</ButtonGroup>

			</>
		),
	}
]


