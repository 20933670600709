import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'react-bootstrap';

const KOLOM = [
	{
		Header: '#',
		Cell: ({row}) => (
			<>
				{parseInt(row.id)+1}
			</>
		)
	},	
	{
		Header:'Pemilik',
		Footer:'Pemilik',
		accessor:'nama_di_stnk',
		Filter:ColumnFilter
	}
	,{
		Header:'NOPOL',
		Footer:'NOPOL',
		accessor:'nopol',
		Filter:ColumnFilter
	}
	,{
		Header:'Warna',
		Footer:'Warna',
		accessor:'warna',
		Filter:ColumnFilter
	}
	,{
		Header:'Pajak Tahunan',
		Footer:'Pajak Tahunan',
		accessor:'pajak_tahunan',
		Filter:ColumnFilter
	}
	,{
		Header:'Pajak  5 Tahun',
		Footer:'Pajak 5 Tahun',
		accessor:'pajak_stnk',
		Filter:ColumnFilter
	}
	,{
		Header:'Cara Perolehan',
		Footer:'Cara Perolehan',
		accessor:'nama_cara_perolehan',
		Filter:ColumnFilter
	}
	,{
		Header: 'Aksi',
		Cell: ({ row, handleRowAction, handleRowActionDelete }) => (
			<>
		<ButtonGroup className="me-2" aria-label="First group">
          <Button variant="primary" onClick={() => handleRowAction(row.original)} size='sm'>
					<FontAwesomeIcon icon={ faEdit } />
		  </Button>
          <Button variant="danger" onClick={() => handleRowActionDelete(row.original)} size='sm'>
					<FontAwesomeIcon icon={ faTrash } />
		  </Button>
        </ButtonGroup>

			</>
		),
	}
]

export default KOLOM;
