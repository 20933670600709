import { faBars, faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

const UserTable = (props) => {  
  
  const [data, setData] = useState(
    document.querySelectorAll("#kategoriTable tbody tr")
  );
  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#kategoriTable tbody tr"));
   // chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{props.title}</h4>
          <Button className="me-2" variant="primary btn-rounded" as={Link} to={`${props.link}/tambah`}>
            <span className="btn-icon-start text-primary">
              <i className="fa fa-plus color-info" />
            </span>
            Baru
          </Button>          
          {/* <Link to={"#"} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faPlus} /> Baru</Link> */}
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="kategoriTable" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      style={{ width: 10 }}
                    >
                      No.
                    </th>
                    <th
                      style={{ width: 70 }}
                    >
                      Nama
                    </th>
                    <th
                      style={{ width: 10 }}
                    >
                      Email
                    </th>
                    <th
                      style={{ width: 10 }}
                    >
                      Kontak
                    </th>
                    <th
                      style={{ width: 10 }}
                    >
                      Tipe
                    </th>
                    <th
                      style={{ width: 10 }}
                    >
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.map((item,key)=>(
                  <tr key={key} role="row" className="even">
                    <td>{++key}</td>
                    <td>{item.first_name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.role_name}</td>
                    <td>
                      <Dropdown className="dropdown ms-auto text-right">
                        <Dropdown.Toggle
                          variant=""
                          className="btn-link i-false"
                          data-toggle="dropdown"
                        >
                        <FontAwesomeIcon icon={faBars} size="1x"/>  
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <Dropdown.Item as={Link} to={`${props.link}/role/${item.id}`}><FontAwesomeIcon icon={faCheck} className="me-2" /> Tipe User</Dropdown.Item>
                          <Dropdown.Item as={Link} to={`${props.link}/edit/${item.id}`}><FontAwesomeIcon icon={faPen} className="me-2" /> Edit</Dropdown.Item>
                          <Dropdown.Item as={Link} to={'${props.link}'} onClick={() => props.onDelete(item.id)}><FontAwesomeIcon icon={faTrash} className="me-2" /> Hapus</Dropdown.Item>
                        </Dropdown.Menu> 
                      </Dropdown>
                    </td>
                  </tr>
                  ))}

                </tbody>
              </table>

              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to={props.link}
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to={props.link}
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to={props.link}
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
