/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';

/// Image
//import user from "../../../images/user.jpg";
import profile from "../../../images/user.jpg";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
	const {
		iconHover
	} = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    const sidebarLinks = document.querySelectorAll(".metismenu a[class='']");
    sidebarLinks.forEach((link) => {
      link.addEventListener("click", () => {
        // Close the sidebar by removing the "menu-toggle" class
        if (aaa.classList.contains("menu-toggle")) {
          aaa.classList.remove("menu-toggle");
        }
      });
    });    

		
  }, []);
  /// Path
  let path = window.location.pathname;
  path = path.split('/')[2];
  // path = path.split('/').slice(2).join('/');
  /// Active menu
  let dashBoard = [
      "",
      "dashboard-dark"
    ],
    master = [
      "jenis_sertifikat",
      "status_tanah",
      "cara_perolehan",
      "bidang",
      "kategori",
      "kategori_kendaraan",
      "model_kendaraan",
      "merek_kendaraan",
      "kode_barang"
    ],
    asset = [
      "tanah",
      "bangunan",
      "ruangan",
      "kendaraan",
      "barang"
    ],
    template = [
      "table-datatable-basic",
      "ui-modal"
    ]

  ;
  return (
    <div
      className={`dlabnav ${iconHover}`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
		  	<Dropdown className="dropdown header-profile2">
			  <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
				<div className="header-info2 d-flex align-items-center border">
				  <img src={profile} width={20} alt="" />
				  <div className="d-flex align-items-center sidebar-info">
					<div>
					  <span className="font-w700 d-block mb-2">Bayu</span>
					  <small className="text-end font-w400">Administrator</small>
					</div>
					<i className="fas fa-sort-down ms-4"></i>
				  </div>
				</div>
			  </Dropdown.Toggle>
				  <Dropdown.Menu align="right" className=" dropdown-menu dropdown-menu-end">
					<Link to="/app-profile" className="dropdown-item ai-icon">
					  <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
						width={18} height={18} viewBox="0 0 24 24" fill="none"
						stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
					  >
						<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
						<circle cx={12} cy={7} r={4} />
					  </svg>
					  <span className="ms-2">Profile </span>
					</Link>
					<LogoutPage />
				  </Dropdown.Menu>
			  </Dropdown> 
        <MM className="metismenu" id="menu">
          
		      <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul >
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard</Link></li>
            </ul>
          </li>
			
          <li className={`${master.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to={'#'} >
              <i className="fas fa-info-circle"></i>
              <span className="nav-text">Master Setup</span>
            </Link>
            <ul >
              <li><Link className={`${path === "jenis_sertifikat" ? "mm-active" : ""}`} to="/jenis_sertifikat">Jenis Sertifikat</Link></li>
              <li><Link className={`${path === "status_tanah" ? "mm-active" : ""}`} to="/status_tanah">Status Tanah</Link></li>
              <li><Link className={`${path === "cara_perolehan" ? "mm-active" : ""}`} to="/cara_perolehan">Cara Perolehan</Link></li>
              <li><Link className={`${path === "bidang" ? "mm-active" : ""}`} to="/bidang">Bidang Bangunan</Link></li>
              <li><Link className={`${path === "kategori" ? "mm-active" : ""}`} to="/kategori">Kategori Bangunan</Link></li>
              <li><Link className={`${path === "model_kendaraan" ? "mm-active" : ""}`} to="/model_kendaraan">Model Kendaraan</Link></li>
              <li><Link className={`${path === "merek_kendaraan" ? "mm-active" : ""}`} to="/merek_kendaraan">Merek Kendaraan</Link></li>
              <li><Link className={`${path === "merek_kendaraan" ? "mm-active" : ""}`} to="/kode_barang">Kode Barang</Link></li>
            </ul>
          </li>
          <li className={`${asset.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to={'#'} >
              <i className="fas fa-info-circle"></i>
              <span className="nav-text">Master Asset</span>
            </Link>
            <ul >
              <li><Link className={`${path === "kategori" ? "mm-active" : ""}`} to="/tanah">Tanah</Link></li>
              <li><Link className={`${path === "bangunan" ? "mm-active" : ""}`} to="/bangunan">Bangunan</Link></li>
              <li><Link className={`${path === "ruangan" ? "mm-active" : ""}`} to="/ruangan">Ruangan</Link></li>
              <li><Link className={`${path === "kendaraan" ? "mm-active" : ""}`} to="/kendaraan">Kendaraan</Link></li>
              <li><Link className={`${path === "barang" ? "mm-active" : ""}`} to="/barang">Barang</Link></li>
            </ul>
          </li>
		      <li className={`${template.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="fas fa-home"></i>
              <span className="nav-text">Template</span>
            </Link>
            <ul >
              <li><Link className={`${path === "datatable" ? "mm-active" : ""}`} to="/table-datatable-basic"> Datatable</Link></li>
              <li><Link className={`${path === "ui-modal" ? "mm-active" : ""}`} to="/ui-modal"> Modal</Link></li>
            </ul>
          </li>

        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
