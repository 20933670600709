import Swal from "sweetalert2";

export function ExportData(data) {
    Swal.showLoading();
    fetch(`https://assetmu.apps.unismuh.ac.id/api/export/${data}`)
        .then(response => response.blob())
        .then(blob => {
            Swal.close();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "exported_data.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
        .catch(error => {
            console.error('Error downloading the file:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Export data gagal',
            });
        });
}

