import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";


const TanahView = () => {
  const { id } = useParams();
  const [data, setData] = useState([])
  const [field, setField] = useState([])
  const [kodeLokasi, setKodeLokasi] = useState([
    '00','00','00','00','00','0000'
  ])
  const [kodeAset, setKodeAset] = useState([
    '00','00','00','00','00','0000'
  ])
  // const [kodeAset, setKodeAset] = useState([
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xx'},
  //   {value:'xxxx'}
  // ])
  const tanggal_sekarang = new Date();

  const [inputs, setInputs] = useState({
    jenis_sertifikat_id:0,
    status_tanah_id:0,
    cara_perolehan_id:0,
    kode_wilayah:0,
    luas:0,
    waktu_perolehan:`${tanggal_sekarang.getFullYear()}-${(tanggal_sekarang.getMonth()+1)}-${tanggal_sekarang.getDate()}`,
    lat:-5.1499781,
    lng:119.4218532
  })

  const [jenisSertifikat, setJenisSertifikat] = useState({});
  const [statusTanah, setStatusTanah] = useState({});
  const [caraPerolehan, setCaraPerolehan] = useState({});
  const [wilayahPropinsi, setWilayahPropinsi] = useState({});
  const [wilayahKota, setWilayahKota] = useState({});
  const [wilayahKecamatan, setWilayahKecamatan] = useState({});


  const [selectedOption, setSelectedOption] = useState(null);

  let endpoint = 'cara_perolehan';
  const updateKodeAset = (index, newValue) => {
    setKodeAset(prevItems =>
      prevItems.map((item, i) => (i === index ? newValue : item)) 
    );
  };
  const updateKodeLokasi = (index, newValue) => {
    setKodeLokasi(prevItems =>
      prevItems.map((item, i) => (i === index ? newValue : item)) 
    );
  };


  const handleChange = (event) => {
    console.log(event);
    if (!event.target) {
      setInputs(values => ({...values, [event.name]: event.value}))
    } else {      
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
        case 'pemegang_hak':
          setInputs(values => ({...values, [name]: value}))
          updateKodeLokasi(0,value)
          break;
        case 'dokumen_sertifikat':
        case 'dokumen_tanah':
          setInputs(values => ({...values, [name]: event.target.files[0]}))
          break;
        default:
          setInputs(values => ({...values, [name]: value}))
          break;
      }
    }
  }
  
  const handleChangeWilayah = (event) => {
    let id_level_wil = event.id_level_wil;
    let kode_dagri = event.kode_dagri;
    console.log(event);
    switch (id_level_wil) {
      case "1":
        UseAPI('wilayah/level/2/'+kode_dagri,{},'GET').then((resp)=>{
          let wilayah = [];
          resp.map((item)=>{
            wilayah.push({label:item.nm_wil,value:item.id,id_level_wil:item.id_level_wil,kode_dagri:item.kode_dagri})
          })
          setWilayahKota(wilayah);
        });      
        break;
      case "2":
        UseAPI('wilayah/level/3/'+kode_dagri,{},'GET').then((resp)=>{
          let wilayah = [];
          resp.map((item)=>{
            wilayah.push({label:item.nm_wil,value:item.id,name:'kode_wilayah',id_level_wil:item.id_level_wil,kode_dagri:item.kode_dagri})
          })
          setWilayahKecamatan(wilayah);
        });      
        break;
    }
  }
   
  const handleSave = (e) => {        
    e.preventDefault();
    console.log("inputs",inputs);
    console.log("lokasi",kodeLokasi);
    console.log("jenisSertifikat",jenisSertifikat);
    console.log("selectedoption",selectedOption);
    console.log("kodeaset",kodeAset);
    console.log("kodelokasi",kodeLokasi);
    let bisa_save = 1;
    Object.entries(inputs).map(([key,val])=>{
      switch (key) {
        case 'cara_perolehan_id':
        case 'jenis_sertifikat_id':
        case 'status_tanah_id':
        case 'kode_wilayah':
          if (val === 0) {
            bisa_save = 0;
          }
          break;
      }
    })
    if (bisa_save === 0) {
      Swal.fire({
        title: 'Gagal',
        text: 'Lengkapi data isian',
        icon: "error",
      }); 

    } else {

      Swal.fire({
        title: "Anda yakin ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          try {
            return UseAPI('tanah/'+id,inputs,'POST').then(async (resp)=>{
              if (resp.status === 'er') {
                return Swal.showValidationMessage(`
                  ${JSON.stringify(await resp)}
                `);
              }
              return resp;
            });
  
            // if (!response.ok) {
            //   return Swal.showValidationMessage(`
            //     ${JSON.stringify(await response.json())}
            //   `);
            // }
            // return response.json();
          } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Konfirmasi',
            text: result.value.pesan,
            icon: "success",
          }); 
        }
      });    
    }
  }
  useEffect(async ()=>{
    await UseAPI(`tanah/${id}`,{},'GET').then((resp)=>{
      console.log('tanah',resp);
      setData(resp.data[0]);
      Object.entries(resp.data[0]).map(([key,val])=>{
        setInputs(values => ({...values, [key]: val}))
      })
      setField(resp.field);
    });  
    UseAPI('jenis_sertifikat',{},'GET').then((resp)=>{
      let jenis_sertifikat = [];
      resp.map((item)=>{
        jenis_sertifikat.push({label:item.nama_jenis_sertifikat,value:item.id,name:'jenis_sertifikat_id'})
      })
      setJenisSertifikat(jenis_sertifikat)
    });  
    UseAPI('status_tanah',{},'GET').then((resp)=>{
      let status_tanah = [];
      resp.map((item)=>{
        status_tanah.push({label:item.nama_status_tanah,value:item.id,name:'status_tanah_id'})
      })
      setStatusTanah(status_tanah);
    });  
    UseAPI('cara_perolehan',{},'GET').then((resp)=>{
      let cara_perolehan = [];
      resp.map((item)=>{
        cara_perolehan.push({label:item.nama_cara_perolehan,value:item.id,name:'cara_perolehan_id'})
      })
      setCaraPerolehan(cara_perolehan);
    });  
    UseAPI('wilayah/level/1',{},'GET').then((resp)=>{
      let wilayah = [];
      resp.map((item)=>{
        wilayah.push({label:item.nm_wil,value:item.id,name:'id_level_wil',id_level_wil:item.id_level_wil,kode_dagri:item.kode_dagri})
      })
      setWilayahPropinsi(wilayah);
    });  
  },[])

  const groupStyles = {
    border: `2px dotted`,
    borderRadius: "5px",
    background: "#f2fcff",
  };
  
  const yesNoOptions = [
    { value: "Ya", label: "Ya" }
    ,{ value: "Tidak", label: "Tidak" }
  ];  
  const handleSelectChange = selectedOption => {
    setSelectedOption(selectedOption);
  };  

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFooter, setModalFooter] = useState("");
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const openModalMap = (title,body,footer,data) => {
    setModalTitle(title);
    setModalFooter(footer);
    handleShowModal();
  }

  return (
    <Fragment>
      <PageTitle
        motherMenu="Tanah"
        activeMenu="Lihat"
        pageContent="Lihat"
      />

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="600"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            src="https://www.google.com/maps?q=-5.1828002097444,119.44124940195428&output=embed"
            title="Google Map"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          {modalFooter}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Data Tanah</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Kode Aset</label>
                    <div className="col-sm-9 col-form-label">
                      {data.kode_asset}                      
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Nomor Sertifikat</label>
                    <div className="col-sm-9">
                      {data.nomor_sertifikat}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">NIB</label>
                    <div className="col-sm-9">
                      {data.nib}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Pemegang Hak Sebelum</label>
                    <div className="col-sm-9">
                      {data.pemegang_hak_sebelum}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Kategori Pemegang Hak</label>
                    <div className="col-sm-9">
                      {data.nama_kategori_pemegang_hak}
                      {/* {field.kategori_pemegang_hak_id && field.kategori_pemegang_hak_id.masterdata.find((item) => item.value === inputs.kategori_pemegang_hak_id)} */}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Nama Pemegang Hak Sekarang</label>
                    <div className="col-sm-9">
                      {data.pemegang_hak}
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Jenis Sertifikat</label>
                    <div className="col-sm-9">
                      {data.nama_jenis_sertifikat}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Status Tanah</label>
                    <div className="col-sm-9"> 
                      {data.nama_status_tanah}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Luas (M2)</label>
                    <div className="col-sm-9">
                      {inputs.luas}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Alamat</label>
                    <div className="col-sm-9">
                      {inputs.alamat}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Lokasi Sertifikat Disimpan</label>
                    <div className="col-sm-9">
                      {inputs.lokasi_sertifikat_disimpan}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Scan Sertifikat (Pdf, max: 2MB)</label>
                    <div className="col-sm-9">
                        <a href={`https://assetmu.apps.unismuh.ac.id/uploads/${inputs.dokumen_sertifikat}`} target="_blank">Lihat</a>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Foto Tanah (Pdf, max: 2MB)</label>
                    <div className="col-sm-9">
                          {
                            inputs.dokumen_tanah && <a href={`https://assetmu.apps.unismuh.ac.id/uploads/${inputs.dokumen_tanah}`} target="_blank">Lihat</a>
                          }

                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Koordinat Lokasi (Lat, Long)</label>
                    <div className="col-sm-9">
                      <a href="#" onClick={() => openModalMap('Lokasi Aset','Lat: '+inputs.lat+' Long: '+inputs.lng,'',{lat:inputs.lat,lng:inputs.lng})}>Buka Map</a>
                      {/* <a href={`https://www.google.com/maps?q=${inputs.lat},${inputs.lng}`} target="_blank">Buka Map</a> */}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Lokasi/Wilayah</label>
                    <div className="col-sm-9">
                      {`${inputs.kecamatan_tanah} ${inputs.kabupaten_tanah} ${inputs.propinsi_tanah}`}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>      
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Data Penggunaan</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Keterangan Penggunaan </label>
                    <div className="col-sm-9">
                      {data.penggunaan}
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
        
      </div>      
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Data Tambahan</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Cara Perolehan </label>
                    <div className="col-sm-9">
                      {inputs.nama_cara_perolehan}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Tanah Berupa Fasum </label>
                    <div className="col-sm-9">
                      {inputs.status_fasum}
                    </div>                      
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Status Sengketa </label>
                    <div className="col-sm-9">
                      {inputs.status_sengketa}
                    </div>                      
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Harga Perolehan </label>
                    <div className="col-sm-9">
                      {inputs.harga_perolehan}
                    </div>                      
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Waktu Perolehan </label>
                    <div className="col-sm-9">
                      {inputs.waktu_perolehan}
                    </div>                      
                  </div>

              </div>
            </div>
          </div>
        </div>
        
      </div>      
    </Fragment>
  );
};

export default TanahView;
