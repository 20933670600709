import React, { Fragment, useEffect, useReducer, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { UseAPI } from "../../../services/UseAPI";
import swal from "sweetalert";
import Tabel from "./Tabel";
import { Button, Modal } from "react-bootstrap";
import FormElementsVertical from "./FormElementsVerical";
import Select from "react-select";
import DaftarBarang from "./DaftarBarang";
import { useParams } from "react-router-dom";

const init =  false;

const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default:	
			return state;
	}	
}

const BarangLihat = () => {
  const {ruang_kode} = useParams();
  let ruangan_id = ruang_kode.split("$$")[0]
  let master_kode_barang_id = ruang_kode.split("$$")[1]

  const [data, setData] = useState([])
  const [field, setField] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  

  const [state ,dispatch] = useReducer(reducer, init);
  const [optionData,setOptionData] = useState([]);
  const [whereCondition,setWhereCondition] = useState('');

  
  const activePag = useRef(0);
  const sort = 5;


  const [ruangan, setRuangan] = useState([])
  const [master_kode_barang, setMasterKodeBarang] = useState([])
  const [kondisi, setKondisi] = useState([])
  const [cara_perolehan, setCaraPerolehan] = useState([])
  useEffect(()=>{
    console.log(ruangan_id);
    UseAPI('barang/?from=0&ruangan_id='+ruangan_id+'&master_kode_barang_id='+master_kode_barang_id,{},'GET').then((resp)=>{
      console.log("barang",resp);
      setData(resp.data);
      setField(resp.field);

      let master_kode_barang_arr = [];
      resp.field.master_kode_barang_id.masterdata.map((item)=>{
        let label = '';
        // if (item.level_kode === 1) {
        //   label = item.nama_master_kode_barang
        // } else if (item.level_kode === 2) {
        //   label = `- ${item.nama_master_kode_barang}`
        // } else if (item.level_kode === 3) {
        //   label = `-- ${item.nama_master_kode_barang}`
        // } else if (item.level_kode === 4) {
        //   label = `--- ${item.nama_master_kode_barang}`
        // } else if (item.level_kode === 5) {
        //   label = `---- ${item.nama_master_kode_barang}`
        // }
        label = item.nama_master_kode_barang;
        master_kode_barang_arr.push({
          label:label
          ,value:item.id
          ,name:'master_kode_barang'
        })
      })
      setMasterKodeBarang(master_kode_barang_arr);


      let ruangan_arr = [];
      resp.field.ruangan_id.masterdata.map((item)=>{
        ruangan_arr.push({
          label:item.nama_ruangan
          ,value:item.id
          ,name:'ruangan_id'
        })
      })
      setRuangan(ruangan_arr);

      let kondisi_arr = [];
      resp.field.kondisi_id.masterdata.map((item)=>{
        kondisi_arr.push({
          label:item.nama_kondisi
          ,value:item.id
          ,name:'ruangan_id'
        })
      })
      setKondisi(kondisi_arr);

      let cara_perolehan_arr = [];
      resp.field.cara_perolehan_id.masterdata.map((item)=>{
        cara_perolehan_arr.push({
          label:item.nama_cara_perolehan
          ,value:item.id
          ,name:'ruangan_id'
        })
      })
      setCaraPerolehan(cara_perolehan_arr);



    });  


  },[])

  const handleDelete = (id) => {
    // console.log("hapus",id);
    UseAPI(`barang/${id}`,'','DELETE').then((resp) => {
      if (resp.status === 'ok') { 
        setData(resp.data);
        swal(resp.pesan, {
          icon: "success",
        });     
        // window.location.reload();
      } else {
        swal(resp.pesan, {
          icon: "danger",
        });    
      }
    })
  }

  const dataOnModal = () =>{
    let dataInput = [];
    Object.key(field).map((key,item)=>{
      return (
        <p>test</p>
      )
    })
  }

  const optionChange = (e) => {
    console.log(e);
    setOptionData(values => ({...values, [e.name]: e.value}))
  }

  const handleCari = () => {
    let queryParam = [];
    console.log(optionData);
    Object.entries(optionData).map(([key,val])=>{
      console.log(val);
      if (parseInt(val) > 0) {
        queryParam.push(`${key}=${val}`)
      }
    })
    setWhereCondition(queryParam.join("&"))
    console.log("setWhereCondition",queryParam.join("&"));
    console.log("whereCondition",queryParam.join("&"));
    if (queryParam.join("&") !== '') {
      UseAPI('barang/?from=0&'+queryParam.join("&"),{},'GET').then(async (resp)=>{
        console.log("barang_cari ada",resp);
        setData(resp.data);
      });  
    } else {
      UseAPI('barang/?from=0&',{},'GET').then(async (resp)=>{
        console.log("barang_cari tidak ada",resp);
        setData(resp.data);
      });  

    }
    dispatch({type:'basicModal'})


  }

  const onPagination = (i) =>{
    console.log("i",i);
    let where = whereCondition ??= ''
    activePag.current = i; 
    if (i <= 0) {
      activePag.current = 0;
    } else if (data.length < window.dataLimit) {
      activePag.current = (i);
    }
    console.log("activePage",activePag.current);
    console.log("datalength",data.length);
    if (where === '') {
      UseAPI('barang/?from='+(activePag.current*window.dataLimit),{},'GET').then((resp)=>{
        console.log("barang_cari",resp);
        setData(resp.data);
      });  
    } else {
      UseAPI('barang/?from='+(activePag.current*window.dataLimit)+'&'+where,{},'GET').then((resp)=>{
        console.log("barang_cari",resp);
        setData(resp.data);
      });  

    }
    // console.log(activePag.current);
  }
  const handleReset = () =>{
    setWhereCondition('')
    UseAPI('barang/?from='+(activePag.current*window.dataLimit),{},'GET').then((resp)=>{
      console.log("barang_cari",resp);
      setData(resp.data);
    });  

    dispatch({type:'basicModal'})
   
  }

  return (
    <Fragment>
			<Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}>
				<Modal.Header>
					<Modal.Title>Filter</Modal.Title>
					<Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
				</Modal.Header>
				<Modal.Body>
            <div className="mb-3 row">
                <label className="col-form-label">{'Ruangan'}</label>
                <Select
                    // value={ruangan[inputs.bidang_id]}
                    onChange={optionChange}
                    options={ruangan}
                    name={'ruangan_id'}
                    id={'ruangan_id'}
                />                                         
            </div>          
            <div className="mb-3 row">
                <label className="col-form-label">{'Kode Barang/Aset'}</label>
                <Select
                    // value={bidang[inputs.bidang_id]}
                    onChange={optionChange}
                    options={master_kode_barang}
                    name={'cara_perolehan_id'}
                    id={'cara_perolehan_id'}
                />                                         
            </div>          
            <div className="mb-3 row">
                <label className="col-form-label">{'Kondisi'}</label>
                <Select
                    // value={bidang[inputs.bidang_id]}
                    onChange={optionChange}
                    options={kondisi}
                    name={'kondisi_id'}
                    id={'kondisi_id'}
                />                                         
            </div>          
            <div className="mb-3 row">
                <label className="col-form-label">{'Cara Perolehan'}</label>
                <Select
                    // value={bidang[inputs.bidang_id]}
                    onChange={optionChange}
                    options={cara_perolehan}
                    name={'cara_perolehan_id'}
                    id={'cara_perolehan_id'}
                />                                         
            </div>          

          {/* {
            Object.entries(field).map(([key,item])=>{
              if (item.tag === 'select') {
                return (
                  <FormElementsVertical
                    key={key}
                    type={item.tag}
                    label={item.label}
                    name={key}
                    datasource={item}
                    onChange={optionChange}
                  />

                )
              }
            })
          } */}
        </Modal.Body>
				<Modal.Footer>
					<Button  variant="warning light" onClick={() => handleReset()}>Reset Filter</Button>
					<Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Batal</Button>
					<Button variant="primary" onClick={()=>handleCari()}>Cari</Button>
				</Modal.Footer>
			</Modal>
      <PageTitle
        activeMenu="Daftar"
        motherMenu="barang"
        pageContent="barang"
      />
      <div className="row">
          {}
          <DaftarBarang
            title="Daftar"
            data={data}
            field={field}
            link={'barang'}
            onDelete={handleDelete}
            showFilterModal={dispatch}
            where={whereCondition}
            onPagination={onPagination}
            activePag={activePag}
            sort={sort}
          />
      </div>
    </Fragment>
  );
};

export default BarangLihat;
