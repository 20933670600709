import React, { useContext } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./step.css";

import Pengaturan from "./components/Master/Pengaturan/Pengaturan";

//user
import User from "./components/Master/User/User";
import UserEdit from "./components/Master/User/UserEdit";
import UserTambah from "./components/Master/User/UserTambah";
import UserRole from "./components/Master/User/UserRole";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Charts
import ChartJs from "./components/charts/Chartjs";


import { ThemeContext } from "../context/ThemeContext";

import JenisSertifikat from "./components/Master/JenisSertifikat/JenisSertifikat";
import JenisSertifikatTambah from "./components/Master/JenisSertifikat/JenisSertifikatTambah";
import StatusTanah from "./components/Master/StatusTanah/StatusTanah";
import StatusTanahTambah from "./components/Master/StatusTanah/StatusTanahTambah";
import CaraPerolehan from "./components/Master/CaraPerolehan/CaraPerolehan";
import CaraPerolehanTambah from "./components/Master/CaraPerolehan/CaraPerolehanTambah";
import Kategori from "./components/Master/Kategori/Kategori";
import Tanah from "./components/Tanah/Tanah";
import TanahTambah from "./components/Tanah/TanahTambah";
import TanahEdit from "./components/Tanah/TanahEdit";

import BasicDatatable from "./components/table/BasicDatatable";
import UiModal from "./components/bootstrap/Modal";
import Bangunan from "./components/Bangunan/Bangunan";
import BangunanTambah from "./components/Bangunan/BangunanTambahForm";
import BangunanEdit from "./components/Bangunan/BangunanEdit";
import JenisSertifikatDetail from "./components/Master/JenisSertifikat/JenisSertifikatDetail";
import KategoriDetail from "./components/Master/Kategori/KategoriDetail";
import KategoriTambah from "./components/Master/Kategori/KategoriTambah";
import Ruangan from "./components/Ruangan/Ruangan";
import PilihBangunan from "./components/Ruangan/PilihBangunan";
import RuanganEdit from "./components/Ruangan/RuanganEdit";
import RuanganTambah from "./components/Ruangan/RuanganTambah";
import Kendaraan from "./components/Kendaraan/Kendaraan";
import KendaraanTambah from "./components/Kendaraan/KendaraanTambah";
import KendaraanDetail from "./components/Kendaraan/KendaraanDetail";
import Barang from "./components/Barang/Barang";
import BarangTambah from "./components/Barang/BarangTambah";
import BarangEdit from "./components/Barang/BarangEdit";
import BangunanTambahForm from "./components/Bangunan/BangunanTambahForm";
import KodeBarang from "./components/Master/KodeBarang/KodeBarang";
import KodeBarangTambah from "./components/Master/KodeBarang/KodeBarangTambah";
import KodeBarangDetail from "./components/Master/KodeBarang/KodeBarangDetail";
import PilihTanah from "./components/Bangunan/PilihTanah";
import DaftarBarang from "./components/Barang/DaftarBarang";
import BarangLihat from "./components/Barang/BarangLihat";
import TanahDocuments from "./components/Tanah/TanahDocuments.js";
import TanahView from "./components/Tanah/TanahView.js";
import BangunanView from "./components/Bangunan/BangunanView.js";
const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    ///master
    { url: "jenis_sertifikat", component: JenisSertifikat },
    { url: "jenis_sertifikat/edit/:id", component: JenisSertifikatDetail },
    { url: "jenis_sertifikat/tambah", component: JenisSertifikatTambah },
    { url: "status_tanah", component: StatusTanah },
    { url: "status_tanah/edit/:id", component: KategoriDetail },
    { url: "status_tanah/tambah", component: StatusTanahTambah },
    { url: "cara_perolehan", component: CaraPerolehan },
    { url: "cara_perolehan/edit/:id", component: KategoriDetail },
    { url: "cara_perolehan/tambah", component: CaraPerolehanTambah },
    { url: "kategori", component: Kategori },
    { url: "kategori/tambah", component: KategoriTambah },
    { url: "kategori/edit/:id", component: KategoriDetail },
    { url: "kode_barang", component: KodeBarang },
    { url: "kode_barang/tambah", component: KodeBarangTambah },
    { url: "kode_barang/edit/:id", component: KodeBarangDetail },


    { url: "pengaturan", component: Pengaturan },

    //user
    { url: "user", component: User },
    { url: "user/edit/:id", component: UserEdit },
    { url: "user/tambah", component: UserTambah },
    { url: "user/role/:id", component: UserRole },


    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    
    ///tanah
    { url: "tanah", component: Tanah },
    { url: "tanah/tambah", component: TanahTambah },
    { url: "tanah/edit/:id", component: TanahEdit },
    { url: "tanah/dokumen/:id", component:TanahDocuments },
    { url: "tanah/view/:id", component:TanahView },

    ///bangunan
    { url: "bangunan", component: Bangunan },
    { url: "bangunan/tambah", component: PilihTanah },
    { url: "bangunan/tambah/:tanah_id", component: BangunanTambahForm },
    { url: "bangunan/edit/:id", component: BangunanEdit },
    { url: "bangunan/view/:id", component:BangunanView },

    ///ruangan
    { url: "ruangan", component: Ruangan },
    { url: "ruangan/tambah", component: PilihBangunan },
    { url: "ruangan/tambah/:bangunan_id", component: RuanganTambah },
    { url: "ruangan/edit/:ruangan_id", component: RuanganEdit },

    ///kendaraan
    { url: "kendaraan", component: Kendaraan },
    { url: "kendaraan/tambah", component: KendaraanTambah },
    { url: "kendaraan/edit/:id", component: KendaraanDetail },

    ///barang
    { url: "barang", component: Barang },
    { url: "barang/tambah", component: BarangTambah },
    { url: "barang/edit/:id", component: BarangEdit },
    { url: "barang/lihat/:ruang_kode", component: BarangLihat },

    //template
    { url: "table-datatable-basic", component: BasicDatatable },
    { url: "ui-modal", component: UiModal },


  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 250 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>      
    </>
  );
};

export default Markup;
