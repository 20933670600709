import React,{ useEffect, useMemo, useState } from 'react';
import PageTitle from "../../layouts/PageTitle";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { KOLOM } from './Columns';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { UseAPI } from '../../../services/UseAPI';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ExportData } from '../../../services/Utils';
export const Ruangan = () => {
  const [sourcedata, setSourceData] = useState([]);
  const columns = useMemo( () => KOLOM, [] )
  let history = useHistory();
  const handleRowAction = (row) => {
    console.log(row);
    history.push("/ruangan/edit/"+row.id);
  }
  const handleRowActionDelete = (row) => {
    Swal.fire({
    title: "Anda yakin ?",
    text: "Data yang sudah dihapus tidak dapat dikembalikan",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Ya"
    }).then((result) => {
      if (result.isConfirmed) {
        UseAPI(`ruangan/${row.id}`,{},'DELETE').then((resp) => {
          if (resp.status === 'ok') { 
          setSourceData(resp.data);
          Swal.fire({
            title: resp.pesan,
            text: "Berhasil dihapus",
            icon: "success"
          });

          } else {
          Swal.fire({
            title: resp.pesan,
            text: "Gagal dihapus",
            icon: "danger"
          });
          }
        })	
      }
    });	
    console.log(row);
  }

  useEffect(() => {
    let ruangan = [];
    UseAPI('ruangan',{},'GET').then((resp)=>{
    console.log("cara_perolehan",resp);
    setSourceData(resp);
    })       
  }, []);  
  
  const data = useMemo( () => sourcedata, [sourcedata] )
  const tableInstance = useTable({
    columns,		
    data,	
    initialState : {pageIndex : 0},
    handleRowAction,
    handleRowActionDelete
  }, useFilters, useGlobalFilter, usePagination)
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance
  const {globalFilter, pageIndex} = state

  return(
    <>
      <PageTitle activeMenu="Daftar" motherMenu="ruangan" />
      <div className="card">
        <div className="card-header">
        <h4 className="card-title">Ruangan</h4>
          <div className="d-flex align-items-center justify-content-between">
            <Dropdown className="dropdown">
              <Dropdown.Toggle as="div" className="btn-link i-false">
              <FontAwesomeIcon icon={faLayerGroup} className="fa-2x" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                <Dropdown.Item as={Link} to={'/ruangan/tambah'} className="dropdown-item">Baru</Dropdown.Item>
                <Dropdown.Item onClick={() => ExportData('ruangan')} className="dropdown-item">Export</Dropdown.Item>
                <Dropdown.Item className="dropdown-item">Filter</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>            

        </div>
        <div className="card-body">
          <div className="table-responsive">
            {sourcedata && (
            <Table {...getTableProps()} striped bordered hover size='sm'>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                        {column.canFilter ? column.render('Filter') : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead> 
              <tbody {...getTableBodyProps()} className="" >
              
                {page.map((row) => {
                  prepareRow(row)
                  return(
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()} style={{padding:5}}> {cell.render('Cell')} </td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            )}
            <div className="d-flex justify-content-between">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{''}
              </span>
              <span className="table-index">
                Go to page : {' '}
                <input type="number" 
                  className="ml-2"
                  defaultValue={pageIndex + 1} 
                  onChange = {e => { 
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
                    gotoPage(pageNumber)
                  } } 
                />
              </span>
            </div>
            <div className="text-center mb-3">	
              <div className="filter-pagination  mt-3">
                <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                
                <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                  Previous
                </button>
                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                  Next
                </button>
                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  
}
export default Ruangan;